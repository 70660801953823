@charset "utf-8";

/* inventory 상세 */
/* 상단 타이틀 영역 */
.detail_page{
	.inner_detail_connect{
		.detail_connect_top{
			.txt_status{
				&.available{
						color:#03BD8D;
						background: rgba(15, 199, 151, 0.15);
				}
				&.allocated{
						color:#E69800;
						background: rgba(236, 170, 0, 0.15);
				}
				&.blocked{
						color:#F73D3F;
						background: rgba(247, 61, 63, 0.15);
				}
				&.aged{
						color:#0F8CE2;
						background: rgba(26, 157, 247, 0.15);
				}
			}
			.btn_comm{
				&.btn_close{
					margin-left: auto;
				}
			}
		}

		/* Croatia Warehouse 영역 */
		app-wms-inventory-information{
			.detail_document{
				padding-top:0;
				width:100%;
				.box_warehouse{
					display: flex;
					flex-wrap: wrap;
					padding-top:20px;
					dl.doc_warehouse{
						display: flex;
						width:50%;
						padding-top:15px;
						dt{
							width:160px;
							letter-spacing: .15px;
							font-weight:$font-medium;
						}
						dd{
							@include text-hidden-default;
							width:210px;
							color:#8B8B9A;
							letter-spacing: .15px;
						}
					}
				}
			}
		}

		/* History 영역 */
		app-wms-inventory-history{
			.tit_data{
				padding:60px 48px 0;
				border-top:1px solid #DFDFE5;
			}
			.wrap_timeline{
				border:0 none;
				padding:28px 48px;
				margin:0;
				.track_status_area{
					margin-top:0;
					li{
						margin-top:8px;
						&:first-child{
							margin-top:0;
						}
						.txt_cont{
							font-size:16px;
						}
						.wrap_location{
							.txt_departed{
								font-weight:$font-light;
							}
							.btn_detail{
								@include btn-static;
								width:100px;
								height:32px;
								font-size:14px;
								margin-left:8px;
								padding:8px 12px;
								color:#1C1C20;
								cursor: pointer;
								letter-spacing: .14px;
								&:hover{
										@include btn-hover;
								}
							}
						}
						/* on일 경우 */
						&.on{
							.txt_date{
								font-weight:$font-light;
							}
						}
					}
				}
			}
		}



		/* Comment 영역 */
	}
}