/* ai scanning 이미지 뷰어 */
ai-scanning-viewer{
    display: block;
    flex-grow:1;
    width: 100%;
    max-width: 680px;
    max-height: 1000px;
    background-color: $color-white;
    .image_viewer{
        max-width: 680px;
        height:100%;
        .img-container{
            position: relative;
            display: block;
            padding:28px 15px 22px;
            .img_top{
                display: flex;
                justify-content: space-between;
                padding: 0 45px 20px;
            }
            /* 이미지 뷰어 페이징 */
            .wrap_page{
                display: flex;
                justify-content: center;
                align-items: center;
                width:50px;
                height:36px;
                background: #000;
                opacity: .35;
                border-radius: 4px;
                span{
                    display: block;
                    color:$color-white;
                    font-size: 12px;
                    font-weight:600;
                    text-align: center;
                }
            }

            /* 이미지 확대/축소/리셋 버튼 */
            .btn-cont.btn-container{
                position: static;
                display:flex;
                justify-content: end;
                .btn_zoom{
                    display: block;
                    width:28px;
                    height:29px;
                    text-indent: -9999px;
                    font-size: 0;
                    cursor: pointer;
                }
                .box_zoom{
                    display: flex;
                    width:134px;
                    height:45px;
                    padding:7px 8px;
                    border:1px solid $color-text;
                    border-radius: 4px;
                    background-color: $color-white;
                    .txt_percent{
                        display:block;
                        width: 62px;
                        font-size:15px;
                        padding:0 12px;
                        line-height: 28px;
                        color:#1C1C20;
                        letter-spacing: -0.15px;
                        text-align: center;
                    }
                    .btn_zoom_in{
                        background: url(../images/ico_zoom_in.svg) 0 0 no-repeat;
                        &:hover{
                            background: url(../images/ico_zoom_in_on.svg) 0 0 no-repeat;
                        }
                    }
                    .btn_zoom_out{
                        background: url(../images/ico_zoom_out.svg) 0 0 no-repeat;
                        &:hover{
                            background: url(../images/ico_zoom_out_on.svg) 0 0 no-repeat;
                        }
                    }
                }
                .btn_zoom_reset{
                    width:45px;
                    height:45px;
                    margin-left:15px;
                    border:1px solid $color-text;
                    border-radius: 4px;
                    background-color: $color-white;
                    cursor: pointer;
                    &:hover{
                        .ico_reset{
                            background: url(../images/ico_reset_on.svg) 0 0 no-repeat;
                        }
                    }
                    .ico_reset{
                        display: block;
                        width:16px;
                        height:16px;
                        margin:0 auto;
                        background: url(../images/ico_reset.svg) 0 0 no-repeat;
                    }
                }
            }

            /* 이미지 이전/다음 버튼 */
            .nav-button-container{
                display: flex;
                justify-content: space-between;
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                bottom:auto;
                margin-top:-18px;
                .btn_order{
                    display: block;
                    position: relative;
                    width:36px;
                    height:36px;
                    font-size: 0;
                    margin:0;
                    border:1px solid $color-text;
                    border-radius:4px;
                    text-indent: -9999px;
                    background-color: $color-white;
                    cursor: pointer;
                    &:disabled{
                        border:1px solid #DFDFE5;
                        background-color: #F5F5FF;
                        cursor:default;
                        .ico_order{
                            &::after{
                                border-top: 1px solid #DFDFE5;
                                border-right: 1px solid #DFDFE5;
                            }
                        }
                    }
                    &:hover:not(:disabled){
                        border:1px solid #1C1C20;
                        box-shadow: 0px 0px 6px 2px rgba(115, 115, 115, 0.50);
                        .ico_order{
                            &::after{
                                border-top: 1px solid #1C1C20;
                                border-right: 1px solid #1C1C20;
                            }
                        }
                    }
                    .ico_order{
                        display: block;
                        width:16px;
                        height:16px;
                        margin:0 auto;
                        &::after{
                            content: '';
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 8px;
                            height: 8px;
                            border-top: 1px solid #1C1C20;
                            border-right: 1px solid #1C1C20;
                        }
                    }
                }

                .btn_prev{
                    right:auto;
                    left:-30px;
                    .ico_prev{
                        position:relative;
                        &::after{
                            margin: -4px 0 0 -2px;
                            transform: rotate(225deg);
                        }
                    }
                }
                .btn_next{
                    right:-30px;
                    .ico_next{
                        position:relative;
                        &::after{
                            margin: -4px 0 0 -5px;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .box_viewer{
                position:relative;
                width:560px;
                height:790px;
                background: $color-white;
                margin:0 auto;
                padding: 22px 20px;
                border-radius: 4px;
                border: 1px solid #F3F3F8;
                box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.50);
                .img_viewer{
                    overflow: hidden;
                    height: 100%;
                    img{
                        display: block;
                        width:100%;
                        height:100%;
                    }
                }
            }

        }
    }
}