@charset "utf-8";

/* ng-zorro custom */
app-select {
  .ant-select {
    font-variant: inherit;
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 45px;
        padding: 0 16px;
        border: 1px solid #d3d3e0;
        border-radius: 4px;
        /* select option이 선택되었을 경우 텍스트 컬러값 */
        .ant-select-selection-search {
          & + .ant-select-selection-item,
          .ant-select-selection-placeholder {
            color: #1c1c20;
          }
        }
        /* select option이 선택되지 않았을 경우 텍스트 컬러값 */
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          font-size: 15px;
          line-height: 45px;
          color: #8b8b9a;
          letter-spacing: -0.15px;
        }
      }
    }
    /* disabled */
    &.ant-select-disabled {
      background-color: $color-main;
      &.ant-select-single {
        .ant-select-selector {
          background-color: $color-main;
        }
        &:hover {
          .ant-select-selector {
            border-color: #d3d3e0;
          }
        }
      }
    }

    .ant-select-arrow {
      right: 16px;
      width: 8px;
      height: 8px;
      border-top: 1px solid #8b8b9a;
      border-right: 1px solid #8b8b9a;
      margin: -4px 0 0 -2px;
      transform: rotate(135deg);
      span {
        display: none;
      }
    }
    .ant-select-clear {
      right: 14px;
    }
  }
}
/* 분기태울 경우 */
.opt_select {
  app-select {
    .ant-select {
      &.ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            color: #1c1c20;
          }
        }
      }

      .ant-select-arrow,
      .ant-select-clear {
        border-top: 1px solid #1c1c20;
        border-right: 1px solid #1c1c20;
      }
    }

    /* 옵션 disabled */
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) {
      &.ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            color: #e1e1e1;
          }
        }
      }

      .ant-select-arrow,
      .ant-select-clear {
        border-top: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;
      }
    }
  }
}
