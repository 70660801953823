@charset "utf-8";

    textarea.ant-input{
        font-size: 15px;
        padding:5px 16px 0 16px;
        border:1px solid #D3D3E0;
        border-radius: 4px;
        color:#1C1C20;
        letter-spacing: -0.15px;
        &:placeholder-shown{
            font-variant: inherit;
            font-size:15px;
            color:#8B8B9A;
            padding-top: 10px;
        }
        &.ant-input-focused, &:focus{
            border-color: $color-primary;
            box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
        }
        &:hover{
            border-color: $color-primary;
        }
        &::placeholder{
          color: #8B8B9A;
        }
    }