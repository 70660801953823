@charset "utf-8";

/* ai scanning 파일 업로드 */
ai-scanning-upload{
    display: block;
    width: 400px;
    padding:0 28px 20px;
        .ant-select{
            width:100%;
            margin-bottom:8px;
        }

    .ant-upload.ant-upload-drag{
        position:relative;
        width: 344px;
        height: 223px;
        padding: 20px;
        border: 0 none;
        border-radius: 4px;
        background: var(--GRADATION-Gradation01, linear-gradient(113deg, #4CBFF5 1.15%, #4B99F4 98.37%));
        .ant-upload-btn{
            background-color: #3C8AED;
            .convey_upload{
                font-size: 15px;
                .ant-upload-text{
                    font-weight:600;
                    color:$color-white;
                    letter-spacing: -0.15px;
                    margin:0;
                    &::before{
                        content:'';
                        display: block;
                        width:28px;
                        height:28px;
                        background: url(../images/ico_ai_load.svg) 0 0 no-repeat;
                        background-size: 28px 28px;
                        margin:0 auto 10px;
                    }
                }
                .ant-upload-hint{
                    font-size:12px;
                    color:#B7B5F1;
                    letter-spacing: 0;
                }
                .txt_browse{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    height: 36px;
                    font-size:14px;
                    font-weight:500;
                    margin:10px auto 0;
                    padding: 10px;
                    border:1px solid #D3D3E0;
                    border-radius: 4px;
                    background-color: $color-white;
                    color:$color-primary;
                    letter-spacing: -0.14px;
                    &::before{
                        content:'';
                        display: inline-block;
                        width:16px;
                        height:16px;
                        background: url(../images/ico_ai_upload.svg) 0 0 no-repeat;
                        margin-right:4px;
                    }
                }

                /* upload loading중일 경우 */
                &.convey_upload_loading{
                    .ant-upload-text{
                        &::before{
                            width: 24px;
                            height: 24px;
                            background: url(../images/icon_loading.svg) 0 0 no-repeat;
                            animation: bounce 1.2s .2s infinite ease;
                        }
                    }
                    .txt_browse{
                        color:#8B8B9A;
                        &::before{
                            background: url(../images/ico_ai_upload_fail.svg) 0 0 no-repeat;
                        }
                    }
                }
            }
        }
    }
}
/* loading 애니메이션 */
@keyframes bounce {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
      }
}
@-webkit-keyframes bounce {
  to {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
      }
}
