@charset "utf-8";

app-ai-scanning-page{
    width: 100%;
 .container{
    overflow: hidden;
    display: flex;
    height:100%;
    .left{
        width: 400px;
        box-shadow: 8px 0px 10px 0px rgba(224, 224, 228, 0.55);
        background-color: $color-white;
        .txt_title{
            padding:40px 28px 20px;
            .tit_top{
                font-size: 22px;
                font-weight: 500;
                letter-spacing: -0.22px;
                color: #000;
            }
        }
    }
    /* 결과리스트가 없을 경우 */
    .left:has(.scanning_empty){
        display: flex;
        flex-direction: column;
        height:calc(100vh - 56px)
    }
    .right{
        display: flex;
        flex-grow: 1;
        margin:40px;
        // background-color: $color-white;
        // box-shadow:0px 2px 8px 0px rgba(219, 219, 227, 0.50);
    }
 }
}