@charset "utf-8";

/* list */
/* search 영역 */
app-freetime-management-search {
  .data_search {
    app-input-text {
      width: 1280px;
    }
  }
}

/* operator,grid list 영역 */
.wrap_table {
  app-freetime-management-operators {
    .btn_data {
      .btn_left {
        display: flex;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 36px;
          padding: 0;
          margin-right: 10px;
          cursor: pointer;
          span {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: 0 4px 0 0;
            vertical-align: top;
          }
          .ico_delete {
            background: url(../images/ico_trash.svg) 0 0 no-repeat;
          }
          .ico_new {
            background: url(../images/ico_plus_16.svg) 0 0 no-repeat;
          }
        }
      }
    }
  }

  /* table */
  .grid_data_table {
    .ant-table-wrapper {
      .ant-table {
        tbody {
          tr {
            td {
              padding: 15px;
              .btn_order_no {
                @include text-hidden-default;
                display: block;
                max-width: 130px;
                white-space: nowrap;
                &.btn_order_master {
                  max-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* detail */
app-freetime-detail {
  .detail_page {
    display: block;
    /* new 버튼 클릭시 상세 슬라이드 오픈 */
    &.open {
      z-index: 99;
      .inner_detail_connect {
        opacity: 1;
        width: 920px;
        /* 내용이 바로 보이지 않도록 딜레이 */
        transition-delay: 0.1s;
      }
    }
    .inner_detail_connect {
      /* new 버튼 클릭 전 숨기기 */
      opacity: 0;
      width: 0;
      height: 100%;
      padding: 48px 0;
      transition:
        opacity,
        width 0.3s ease-in-out;
      /* freetime conditions */
      .detail_doc_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 48px 0;
        .serial_number {
          display: block;
          width: 200px;
          font-size: 22px;
          font-weight: $font-bold;
          letter-spacing: 0.22px;
        }
        .box_btn {
          display: flex;
          justify-content: end;
          width: 100%;
          button {
            display: block;
            margin-top: 0;
          }
        }
      }

      /* basic 영역 */
      app-freetime-detail-form {
        .detail_document {
          .wrap_inp_temp {
            display: flex;
          }
        }
      }

      /* contatiner offset 영역 */
      app-freetime-detail-offset-form {
        /* 하단 여백 */
        .inner_container {
          padding-bottom: 20px;
        }
        .detail_doc_contatiner {
          .temp_row {
            &:first-child {
              width: 80%;
            }
          }
          /* add 추가 영역 */
          .add_contatiner {
            .tbl_temp {
              table {
                overflow: hidden;
                width: 100%;
                background-color: #f3f3f8;
                thead {
                  th {
                    &:first-child {
                      padding-left: 62px;
                    }
                    .lab_temp {
                      display: -webkit-box;
                      height: 57px;
                      font-size: 16px;
                      letter-spacing: -0.16px;
                      white-space: normal;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      text-align: left;
                      color: #1c1c20;
                      line-height: 57px;
                    }
                  }
                }
                tbody {
                  tr {
                    &:first-child {
                      td {
                        .btn_delete {
                          top: 28px;
                        }
                      }
                    }
                    &:last-child {
                      td {
                        .inp_temp {
                          padding-bottom: 10px;
                        }
                      }
                    }
                    td {
                      position: relative;
                      margin-right: 10px;
                      /* 삭제 버튼 */
                      .btn_delete {
                        position: absolute;
                        left: 17px;
                        top: 18px;
                        width: 28px;
                        height: 28px;
                        font-size: 0;
                        background-color: #dfdfe5;
                        border-radius: 4px;
                        cursor: pointer;
                        &::after,
                        &::before {
                          @include btn-close;
                          //height: 12px;
                          margin: -6px 0 0 0;
                          background-color: #8b8b9a;
                        }
                        &::after {
                          transform: rotate(45deg);
                        }
                        &::before {
                          transform: rotate(-45deg);
                        }
                      }
                      &:nth-child(2) {
                        .inp_temp {
                          app-select {
                            width: 284px;
                          }
                        }
                      }

                      .inp_temp {
                        margin-right: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
