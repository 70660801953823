/* box-sizing 규칙을 명시합니다. */
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
address,
em,
img,
strong,
sub,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
nav,
output,
ruby,
section,
summary,
audio,
video,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
textarea,
blockquote,
input,
select,
button {
  margin: 0;
  padding: 0;
  border: 0 none;
  font-size: 15px;
  font-style: normal;
  vertical-align: middle;
  letter-spacing: 1px;
  font-family: 'Pretendard', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', Helvetica, sans-serif;
  font-weight: 400;
}
img {
  display: block;
}
body {
  position: relative;
  // min-width: 1920px;
  height: auto;
  font-size: 14px;
  line-height: 1;
  -webkit-text-size-adjust: none;
  background-color: #f3f3f8;
}
input:checked[type='checkbox'] {
  background-color: #666;
}
html input[type='button'],
input[type='email'],
input[type='password'],
input[type='reset'],
input[type='search'],
input[type='submit'],
input[type='tel'],
input[type='text'] {
  border-radius: 0;
}
//대체텍스트 관련
.screen_out {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  text-indent: -9999px;
}
.ir_pm {
  display: block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}
.ir_wa {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100%;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
