@charset "utf-8";

.convey_head{
    app-header{
        display: flex;
        justify-content: space-between;
        .tit_top{
            width:227px;
            height:30px;
            margin: 12px 0 0 120px;
            background: url(../images/logo_e2e.png) 0 0 no-repeat;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 227px 30px;
            background-color: transparent;
        }
        .list_head{
            display: flex;
            justify-content: end;
            height:100%;
            margin: 5px 29px 0 0;
            li{
                position:relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 11px;
                .ico_comm{
                    display:block;
                }
                .btn_login{
                    width:18px;
                    height:18px;
                    margin-top:5px;
                    background: url(../images/ico_user.svg) 0 0 no-repeat;
                }
                .box_logout{
                    position:absolute;
                    left:-150px;
                    top:30px;
                    width:180px;
                    height:52px;
                    padding-left:36px;
                    border: 1px solid #F3F3F8;
                    background-color: $color-white;
                    &::before{
                        content:'';
                        position: absolute;
                        left: 16px;
                        top: 50%;
                        width: 16px;
                        height: 16px;
                        margin-top:-8px;
                        background: url(../images/ico_logout.svg) no-repeat 0 0;
                    }
                    &:hover{
                        background-color: $color-main;
                        &::before{
                            background: url(../images/ico_logout_on.svg) no-repeat 0 0;
                        }
                        .txt_logout{
                            color:#1C1C20;
                        }
                    }
                    .txt_logout{
                        font-size: 15px;
                        line-height:50px;
                        text-align: left;
                        color:#8B8B9A;
                    }
                }
                .btn_corechat{
                    margin-top: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}