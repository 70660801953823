@charset "utf-8";

.intro_view{
    padding:110px;
    background-color: $color-white;
    .txt_scan{
        font-size:20px;
        font-weight:800;
        color:$color-primary;
        letter-spacing: -.2px;
    }
    .tit_scan{
        display: block;
        padding-top:40px;
        font-size:50px;
        font-weight:700;
        color:#1C1C20;
        letter-spacing: -.5px;
    }
    .txt_scan_info{
        padding-top:40px;
        font-size:18px;
        font-weight:400;
        color:#4D4D51;
    }
    .img_scan_info{
        display: flex;
        width:1120px;
        padding-top:80px;
        .ico_comm{
            width:550px;
            height:328px;
        }
        .img_scan_doc{
            background: url(../images/img_scan_v1.jpg) 0 0 no-repeat;
            background-size: 550px 328px;
            margin-right:20px;
        }
        .img_scan_auto{
            background: url(../images/img_scan_v2.jpg) 0 0 no-repeat;
            background-size: 550px 328px;
        }
    }
    .ant-upload.ant-upload-select{
        margin-top:80px;
    }
    .btn_load{
        display: inline-block;
        width:220px;
        height:64px;
        font-size:18px;
        font-weight:500;
        border-radius: 4px;
        background-color: $color-primary;
        text-align: center;
        color:$color-white;
        letter-spacing: -0.18px;
        cursor: pointer;
        &:hover{
            @include btn-solid;
        }
    }
    /* 파일 업로드 될 경우 버튼 disabled */
    .ant-upload-disabled{
        .btn_load{
            background-color: #DFDFE5;
            color:#8B8B9A;
            cursor: inherit;
        }
    }
}
