@charset "utf-8";

footer {
  height:12%;
  background-color: $color-main;
  color: #8B8B9A;
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding:20px 40px 30px 40px;
    .foot_left{
      display: flex;
      flex-direction: column;
      justify-content: end;
      ul {
        display: flex;
        padding-bottom: 7px;
        li {
          a {
            font-size:16px;
            font-weight:600;
            color: #5e5e68;
          }
          &:first-child {
            a {
              &::after {
                content: "|";
                padding: 0 25px;
                color:#D3D3E0;
              }
            }
          }
        }
      }

      .txt_company{
        font-size:15px;
        padding-top:12px;
        letter-spacing: -.15px;
        .txt_company_info{
          font-weight:600;
          &::after {
            content: "|";
            padding: 0 16px;
            color:#D3D3E0;
          }
        }
        .txt_email{
          display: inline-block;
          padding-left: 10px;
          letter-spacing: -.15px;
        }
      }
    }

    .footer-right {
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding-bottom: 10px;
      text-align: right;
      p{
        padding-top:12px;
        letter-spacing: .15px;
        &:first-child{
          padding-top:0;
        }
      }
    }


  }
}