@charset "utf-8";

/* 자주 사용하는 스타일 */

/* color setting mixin */
$color-main: #f3f3f8;
$color-lnb: #171725;
$color-text: #d3d3e0;
$color-text2: #1c1c20;
$color-text3: #333337;
$color-text4: #5e5e68;
$color-white: #fff;
$color-black: #000;
$color-text-gray: #6f6f80;
$color-primary: #4b99f4;
$color-line: #d9d9d9;
$color-point: #8b8b9a;
$color-sub: #3b87f1;
$color-background: #ebf4ff;

/* border */
$b-radius: 4px;
$b-line-gray: #dfdfe5;
$b-hover-line: #a3a3b2;

/* font weight */
$font-light: 400;
$font-medium: 500;
$font-bold: 600;

/* 각 페이지 상단 타이틀 */
@mixin title-text {
  display: block;
  padding: 60px 0 20px;
  font-size: 30px;
  font-weight: $font-bold;
  color: $color-text2;
  line-height: 36px;
  letter-spacing: 0.3px;
}

/* 상단 검색 영역 */
@mixin search-area {
  display: flex;
  padding: 32px 40px;
}

/* 검색 바 */
@mixin txt-bar {
  padding: 0 16px;
  color: $color-text;
}

/* border 공통 */
@mixin border-line {
  border-radius: $b-radius;
  border: 1px solid $b-line-gray;
}

/* 텍스트 말줄임 기본 1줄*/
@mixin text-hidden-default {
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* 텍스트 말줄임 2줄 */
@mixin text-hidden {
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* flex mixin */
@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-spacebetween {
  display: flex;
  justify-content: space-between;
}

@mixin flex-between-column {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

/* 스크롤 관련 mixin - 종방향 */
@mixin overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

/* 횡방향 */
@mixin overflow-x {
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* 이미지 가운데 정렬 flex */
@mixin flex-column-all-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 이미지 가운데 정렬 position */
@mixin content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* 이미지(대체텍스트 관련) */
@mixin img-text-hidden {
  display: block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}

/**
*
  button
*
**/
/* button solid */
@mixin btn-solid {
  background-color: $color-sub;
  border-radius: 4px;
}
@mixin btn-solid2 {
  background-color: $color-primary;
  border-radius: 4px;
  color: $color-white;
}
@mixin btn-solid2-hover {
  background-color: $color-sub;
  color: $color-white;
  font-weight: $font-medium;
}

/* button gray line */
@mixin btn-gray-line {
  border: 1px solid #1c1c20;
  border-radius: 4px;
  background-color: #fff;
}
/* button gray line hover */
@mixin btn-gray-line-hover {
  background-color: #f3f3f8;
  font-weight: $font-medium;
}

/* button purple line */
@mixin btn-purple-line {
  border: 1px solid $color-primary;
  border-radius: 4px;
  color: $color-primary;
  background-color: #fff;
}
/* button purple line hover */
@mixin btn-purple-line-hover {
  background-color: $color-background;
  font-weight: $font-medium;
}

/* button static 36 - size는 개별 적용 */
@mixin btn-static {
  position: relative;
  border-radius: 4px;
  border: 1px solid #d3d3e0;
  background-color: #fff;
}
/* button hover */
@mixin btn-hover {
  position: relative;
  border-radius: 4px;
  border: 1px solid #1c1c20;
  background-color: #fff;
  // box-shadow: 0px 0px 6px 2px rgba(115, 115, 115, 0.50);
}
/* button disabled */
@mixin btn-disabled-border {
  border: 1px solid #d3d3e0;
  background-color: #fff;
  color: #a3a3b2;
  cursor: default;
}
@mixin btn-disabled-border-none {
  background-color: #dfdfe5;
  color: #8b8b9a;
  cursor: default;
}

/* tracking flag */
@mixin txt_flag {
  display: inline-block;
  font-size: 12px;
  font-weight: $font-bold;
  padding: 8px 12px;
  background: #ebf4ff;
  border-radius: 4px;
  color: $color-primary;
  line-height: 14px;
}

/*
  css 아이콘
*/
/* 화살표,check arrow 아이콘*/
@mixin btn_arrow {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #8b8b9a;
  border-right: 1px solid #8b8b9a;
  margin: -3px 0 0 0;
  transform: rotate(135deg);
}

/*
  X 아이콘
  before, after에
  transform: rotate(45deg);
  transform: rotate(-45deg);
  각각 추가
*/
@mixin btn-close {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 12px;
  margin: -6px 0 0 6px;
  background-color: #322f70;
}

@mixin btn-plus {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 12px;
  margin: -6px 0 0 6px;
  background-color: #322f70;
}

/* .btn {
  align-items: center;
  background-color: var(--btn-background, var(--color-text-reversed));
  border-radius: var(--btn-border-radius);
  border: var(--btn-border-size, 2em) solid var(--btn-border-color, var(--color-border));
  color: var(--btn-color, var(--color-text));
  display: inline-flex;
  justify-content: center;
  padding: var(--btn-padding, 0.5em 1.1em);
} */
