@charset "utf-8";

/* 웹폰트 정의 */
/* 400, 500, 600 3가지 사용, 추후 변경 가능성 있음 */
@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    font-display: swap;
}