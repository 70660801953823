@charset "utf-8";

app-link-editor-page{
	/* search 영역 */
	.search_data{
		.data_search{
			flex-direction: column;
			border:1px solid $b-line-gray;
			.wrap_search{
				display: flex;
				margin-top:12px;
				app-input-text{
					width:1411px;
				}
			}
			.wrap_list_check{
				display: flex;
				margin-top:28px;
				/* radio */
				label{
					margin:0 12px 0 0;
					/* 기본 */
					.ant-radio{
						top: .3em;
						.ant-radio-inner{
							width:20px;
							height:20px;
							border-radius: 100%;
							border-color:$color-text;
							&::after{
								width:12px;
								height:12px;
								margin:-6px 0 0 -6px;
								transform: scale(1);
							}
						}
					}
					/* 선택 했을때 */
					.ant-radio{
						&.ant-radio-checked{
							.ant-radio-inner{
								border-color: $color-primary;
								background-color: $color-white;
								&::after{
									background-color: $color-primary;
									// background: url(../images/ico_check.svg) 0 0 no-repeat;
								}
							}
							&::after{
								border: 1px solid $color-primary;
							}
						}
					}
				}
			}
		}
	}

	/* document select 영역 */
	.wrap_select{
		display: flex;
		margin-top:36px;
		.doc_list_select{
			overflow: hidden;
			width:786px;
			height:780px;
			border:1px solid $b-line-gray;
			border-radius: 4px;
			.tit_select_text{
				display: block;
				font-size:16px;
				font-weight:$font-bold;
				padding:20px 36px 20px;
				background-color: $color-main;
				letter-spacing: -.16px;
				color:$color-text2;
			}
			/* item list */
			.box_doc{
				overflow-y: auto;
				width:100%;
				height:722px;
				border:0 none;

				/* item */
				.example-item{
					margin-bottom: 12px;
					padding:0 28px;
					&:first-child{
						&.item_head{
							border-top:0 none;
							margin-top:0;
						}
					}
					/* item title 영역 */
					&.item_head{
						margin-top:16px;
						padding-top:28px;
						border-top:1px solid $b-line-gray;
						.lab_check{
							.inp_check{
								.ico_check{
									width:20px;
									height:20px;
								}
								input{
									/* 체크박스 disabled */
									&:disabled{
										cursor: default;
										&+.ico_check{
											border:1px solid $color-text;
											background-color: $color-main;
											pointer-events: none;
											&::after{
												display: none;
											}
										}
									}
								}
							}
						}
					}
					/* item right */
					&.example-box{
						.list_doc_info{
							margin-left:12px;
						}
					}
				}

				.doc_title{
					display: inline-block;
					font-size:16px;
					font-weight:$font-bold;
					color:$color-text2;
					letter-spacing: -.16px;
				}

			}

			/* 우측 아이템 영역 */
			.example-container{
				border-top:1px solid $b-line-gray;
				&:first-child{
					border-top:0 none;
				}
				&:last-child{
					border-bottom:1px solid $b-line-gray;
				}
				.example-item{
					.cont_sort{
						.example-handle{
							position: relative;
							width:16px;
							height:16px;
							margin-right: 12px;
							&:last-child{
								margin-right:0;
							}
							&:hover{
								/* 말풍선 툴팁 */
								&:before{
									content:'';
									position: absolute;
									top: 22px;
									left:2px;
									width:14px;
									height:12px;
									background: url(../images/ico_tip.svg) 0 0 no-repeat;
								}
								&:after{
									position: absolute;
									left:-19px;
									top:31px;
									width:64px;
									height:38px;
									font-size:14px;
									background-color: #333337;
									border-radius: 4px;
									letter-spacing: .14px;
									line-height:38px;
									text-align: center;
									text-indent:0;
									color:#fff;
									z-index: 3;
								}
							}
						}
						.btn_comm{
							width:16px;
							height:16px;
							background-repeat: no-repeat;
							background-position: 50% 50%;
							background-color: transparent;
							cursor: pointer;
						}
						/* 복사 버튼 */
						.copy-handle{
							.btn_comm{
								background-image: url(../images/ico_copy.svg);
								&:hover{
									background-image: url(../images/ico_copy_h.svg);
								}
							}
							/* 말풍선 툴팁 */
							&:hover{
								&:after{
									content:'Copy';
								}
							}
						}
						/* 이동 버튼 */
						.move-handle{
							.btn_comm{
								background-image: url(../images/icon_move.svg);
								&:hover{
									background-image: url(../images/icon_move_h.svg);
								}
								cursor: move;
							}
							/* 말풍선 툴팁 */
							&:hover{
								&:after{
									content:'Move';
								}
							}
						}
						/* 삭제 버튼 */
						.delete-handle{
							.btn_comm{
								background-image: url(../images/ico_delete_item.svg);
								&:hover{
									background-image: url(../images/ico_delete_item_h.svg);
								}
							}
							/* 말풍선 툴팁 */
							&:hover{
								&:after{
									content:'Delete';
									left: -22px;
								}
							}
						}
					}
				}
			}
		}

		/* select 중간 버튼 */
		.btn_grid{
			@include flex-column-all-center;
			margin:0 16px;
			.btn_comm{
				width:36px;
				height:36px;
				@include btn-static;
				cursor: pointer;
				&::before{
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					@include btn_arrow;
					margin: -4px 0 0 -5px;
					transform: rotate(45deg);
				}
			}
		}
	}

	/* 하단 버튼 영역 */
	.box_btn{
		display: flex;
    justify-content: end;
		button{
			/* 버튼 disabled */
			&:disabled{
				border:1px solid $b-line-gray;
				background-color: $color-white;
				cursor: default;
				color:#A3A3B2;
				pointer-events: none;
			}
			&:nth-child(2){
				&:disabled{
					background-color: $b-line-gray;
					color:#8B8B9A;
				}
			}
		}
	}
}

/* 아이템 드래그, 기본 공통 */
.list_doc_info{
	display: flex;
	justify-content: space-between;
	margin-left:44px;
	.cont_sort{
		display: flex;
		&:first-child{
			.txt_doc_info{
				font-weight:$font-medium;
				color:$color-text3;
			}
		}
		.txt_doc_type{
			width: 132px;
			color:#4D4D51;
		}
		.txt_doc_name{
			//@include text-hidden-default;
			width: 80px;
		}
	}
	.txt_doc_info{
		display: inline-block;
		margin-right:16px;
		color:#6F6F80;
		line-height: 32px;
		letter-spacing: -.15px;
		.btn_doc_no{
			@include text-hidden-default;
			display: block;
			max-width:198px;
			white-space: nowrap;
			font-size: 14px;
			font-weight: 500;
			padding: 7px 12px;
			border: 1px solid #D3D3E0;
			border-radius: 4px;
			background: #fff;
			line-height: 16px;
			letter-spacing: 0.14px;
			cursor: pointer;
		}
		&.txt_doc_date{
			width:160px;
		}
	}
}

/* 아이템 드래그 */
.cdk-drag-preview {
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow:
		0 5px 5px -3px rgba(0, 0, 0, 0.2),
		0 8px 10px 1px rgba(0, 0, 0, 0.14),
		0 3px 14px 2px rgba(0, 0, 0, 0.12);
	.cdk-drag-placeholder {
		opacity: 0;
	}
	.cdk-drag-animating {
		transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
	}
	.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
		transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
	}
}