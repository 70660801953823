@charset "utf-8";

/* AIscanning 파일 결과 리스트 */
ai-scanning-list{
    display: block;
    height: 100%;
    background-color: $color-white;
    .result_item{
        &.result_on{
            .list_file{
                background: $color-background;
                box-shadow: 0px 2px 6px 0px rgba(75, 153, 244, 0.35);
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 400px;
                    height: 100%;
                    border-top: 1px solid rgba(76, 155, 245, 0.30);
                    border-bottom: 1px solid rgba(76, 155, 245, 0.30);
                }
            }
        }
    }

    .txt_file_result{
        height:58px;
        padding-left: 28px;
        line-height: 58px;
        border-top:1px solid #DFDFE5;
        border-bottom:1px solid #DFDFE5;
        .tit_result,.result_num{
            display: inline-block;
            color:#1C1C20;
            font-size:18px;
            font-weight:600;
            letter-spacing: -0.18px;
            vertical-align: top;
        }
    }
    .example-header,.example-footer{
        display: block;
        font-size: 14px;
        font-weight:600;
        padding:20px 0 12px 28px;
        color:#1C1C20;
        letter-spacing: -0.14px;
    }

    /* 결과 리스트 */
    .result_file{
        /*overflow: auto;
        height: 100%;
        position: sticky;
        top: 80px;*/
        height: 100%;
        max-height: calc(-120px + 100vh);
        border-bottom: 1px solid rgb(238, 238, 238);
        overflow: hidden scroll;
        /*scrollbar-width: none;*/
    }

    /* 결과가 없을 경우 */
    .scanning_empty{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:100%;
        background-color: $color-white;
        .cont_empty{
            margin:0 auto;
            width:120px;
            .img_empty{
                display: block;
                width:120px;
                height:120px;
                font-size:0;
                text-indent:-9999px;
                background: url(../images/img_empty_v2.png) 0 0 no-repeat;
                background-size: 120px 120px;
            }
            .txt_empty{
                display: block;
                padding-top:16px;
                text-align: center;
                color: #8B8B9A;
            }
        }
    }

    /* 스크롤 이슈 */
    .result_list{
        height: calc(100vh - 492px);
    }

    .cdk-virtual-scrollable{
        contain:none;
        overflow: hidden auto;
        .list_file{
            position:relative;
            display: flex;
            width: 400px;
            padding: 16px 28px;
            &.file_scanned{
                cursor:pointer;
            }
            .tit_list{
                color:$color-text2;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: -0.14px;
            }
            .img_type{
                display: block;
                overflow: hidden;
                width:30px;
                height:30px;
                margin-right:14px;
                font-size: 1px;
                line-height: 0;
                text-indent: -9999px;
                &.img_type_pdf{
                    background: url(../images/img_file.png) 0 0 no-repeat;
                    background-size: 30px 30px;
                }
                &.img_type_image{
                    background: url(../images/img_images.png) 0 0 no-repeat;
                    background-size: 30px 30px;
                }
                &.img_type_none{
                    background: url(../images/img_no_file.png) 0 0 no-repeat;
                    background-size: 30px 30px;
                }
            }

            .file_right{
                display: block;
                width:270px;
                .txt_status{
                    font-size: 12px;
                    font-weight: 600;
                    color:$color-primary;
                    .file_type{
                        font-size: 12px;
                        color:#5E5E68;
                        font-weight: 600;
                        &:first-child{
                            &::after{
                                content: "|";
                                padding: 0 7px;
                                color: #D3D3E0;
                            }
                        }
                        &+.file_name{
                            color:#1C1C20;
                        }
                    }
                    &.txt_status_fail{
                        color:#F73D3F;
                    }
                }

                .file_name{
                    display: -webkit-box;
                    // height:32px;
                    padding-top:4px;
                    font-size: 14px;
                    font-weight: 400;
                    color:#8B8B9A;
                    line-height: 16px;
                    letter-spacing: -0.14px;
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-align: left;
                    &.file_name_complete{
                        color:#1C1C20;
                    }
                }
                .txt_user{
                    display: block;
                    padding-top:5px;
                    .file_user_name{
                        font-size: 12px;
                        color:#8B8B9A;
                    }
                    .file_time{
                        font-size: 12px;
                        color:#8B8B9A;
                        &:nth-child(1){
                            &::before{
                                content: "|";
                                padding: 0 7px;
                                color: #D3D3E0;
                            }
                        }
                    }
                }
            }

            /* file status */
            .file_status_sort{
                display: flex;
                align-items: center;
                .file_status{
                    /* 검색결과 loading */
                    font-size:0;
                    text-indent: -9999px;
                    &.loading{
                        &::before{
                            top:50%;
                            right:29px;
                            margin-top:-9px;
                        }
                    }
                    /* 검색결과 complete */
                    &.complete{
                        display: block;
                        width: 18px;
                        height:18px;
                        background-image: url(../images/ico_check_small.svg);
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-color: $color-primary;
                        border-radius: 100%;
                    }
                    /* 검색결과 delete */
                    &.delete{
                        position: relative;
                        width: 16px;
                        height:16px;
                        margin-left:8px;
                        border-radius: 100%;
                        background-color: $b-line-gray;
                        cursor: pointer;
                        z-index: 2;
                        &::after, &::before{
                            @include btn-close;
                            left:48%;
                            height:8px;
                            margin:-4px 0 0 0;
                            background-color: $color-point;
                        }
                        &::after{
                            transform: rotate(45deg);
        
                        }
                        &::before{
                            transform: rotate(-45deg);
                        }
                        &:hover{
                            background-color: $color-primary;
                            &::after, &::before{
                                background-color: $color-white;
                            }
                        }
                    }
                }
            }
        }
    }

}
