@charset "utf-8";

/* ng-zorro custom */
/* 캘린더 select 태그*/
app-date-picker{
  .ant-picker-focused{
    box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
  }
  .ant-picker-focused, .ant-picker:hover{
    border-color: $color-primary;
  }
  /* date 2개 */
  .ant-picker-range{
    height:45px;
    border-radius: 4px;
    .ant-picker-input{
      input::placeholder{
        font-size:15px;
        color:#8B8B9A;
        letter-spacing: -0.15px;
      }
    }
  }
  /* datepicker 전체페이지 placeholder */
  .ant-picker-input{
    input::placeholder{
      font-size:15px;
      color:#8B8B9A;
      letter-spacing: -0.15px;
    }
  }
}

/* 캘린더 */
.view_calendar{
  /* 캘린더 1개 노출 */
  .ant-picker-panel-container{
    .ant-picker-date-panel, .ant-picker-decade-panel, .ant-picker-month-panel, .ant-picker-quarter-panel,
    .ant-picker-time-panel, .ant-picker-week-panel, .ant-picker-year-panel{
      width:393px;
      .ant-picker-body{
        .ant-picker-content{
          width:339px;
        }
      }
    }
  }

  /* 캘린더 2개 노출 */
  .ant-picker-range-wrapper{
    .ant-picker-panel-container{
      box-shadow: 0px 15px 30px 1px #D8D8DF;
      .ant-picker-panels{
        border:1px solid #DFDFE5;
        border-radius: 4px;
      }
    }
  }

  /* datepicker 공통 */
  .ant-picker-panel{
    padding: 48px 0 40px 0;
    .ant-picker-panel{
      padding:0;
      border:0 none;
    }
    .ant-picker-date-panel, .ant-picker-decade-panel, .ant-picker-month-panel, .ant-picker-quarter-panel,
    .ant-picker-time-panel, .ant-picker-week-panel, .ant-picker-year-panel{
      width:392px;

      /* datepicker 상단 */
      .ant-picker-header{
        height:28px;
        padding: 0 25px;
        border-bottom: 0 none;
        align-items: center;
        .ant-picker-header-view{
          button{
            font-size:18px;
            font-weight:600;
            color:#000;
          }
        }
        button:not(.ant-picker-header-view button){
          width: 28px;
          height: 28px;
          margin:0 2px;
          border-radius: 4px;
          border:1px solid #D3D3E0;
          line-height: 25px;
          color:#8B8B9A;
        }
      }

      /* 요일, 날짜 */
      .ant-picker-body{
        padding:28px 0 0;
        .ant-picker-content, .ant-picker-panel-container .ant-picker-panel table{
          width:339px;
          margin:0 auto;
          th{
            color:#8B8B9A;
            letter-spacing: -0.15px;
          }
          .ant-picker-cell{
            color:#8B8B9A;
            padding:8px 12px;
            .ant-picker-cell-inner{
              letter-spacing: -0.15px;
            }
          }
        }

        .ant-picker-cell-in-view{
          color:#1C1C20;
          /* 오늘 날짜 */
          &.ant-picker-cell-today .ant-picker-cell-inner::before{
            border:1px solid $color-primary;
            border-radius: 100%;
          }

          &.ant-picker-cell-range-end,&.ant-picker-cell-range-start,&.ant-picker-cell-selected{
            .ant-picker-cell-inner{
              border-radius: 100%;
              background-color: $color-primary;
              color:#fff;
            }
          }

          /* datepicker 날짜와 날짜 사이 배경색 */
          &.ant-picker-cell-in-range::before{
            background-color: $color-primary;
            opacity: .2;
          }
          &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
          &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before{
            background-color: $color-primary;
            opacity: .2;
          }

          /* datepicker 마우스오버 도트라인 제거 */
          &.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
          &.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
          &.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{
            border: 0 none;
          }
        }
      }
    }
  }
}