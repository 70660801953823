@charset "utf-8";

/* data-management template setting 페이지 */
app-data-management-page {
  .data_management {
    display: block;
    width: 1720px;
    margin: 0 60px 0;
    padding-bottom: 60px;

    /* tab 영역 */
    .tab_data {
      display: flex;
      margin-bottom: 17px;
      data-management-tabs {
        overflow: hidden;
        width: 100%;
      }
      /* 우측 추가,설정 버튼 영역 */
      .btn_bundle {
        position: relative;
        display: flex;
        height: 36px;
        border-bottom: 1px solid #d3d3e0;
        button {
          display: inline-block;
          width: 16px;
          height: 16px;
          padding: 18px;
          cursor: pointer;
          &.btn_add {
            background: url(../images/btn_temp_add.svg) 0 0 no-repeat;
            &:hover {
              /* 말풍선 툴팁 */
              &:before {
                content: '';
                position: absolute;
                right: 6px;
                top: 32px;
                width: 14px;
                height: 12px;
                background: url(../images/ico_tip.svg) 0 0 no-repeat;
              }
              &:after {
                content: 'New Template';
                position: absolute;
                left: -19px;
                top: 40px;
                width: 120px;
                height: 38px;
                font-size: 14px;
                background-color: #333337;
                border-radius: 4px;
                letter-spacing: 0.14px;
                line-height: 38px;
                text-indent: 0;
                color: #fff;
                z-index: 3;
              }
            }
          }
          &.btn_set {
            background: url(../images/btn_temp_set.svg) 0 0 no-repeat;
          }
        }
      }
    }

    /* 검색 영역 */
    data-management-search {
      .data_search {
        display: flex;
        background-color: $color-white;
        padding: 32px 40px;
        box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
        border-radius: 4px;
        /* input */
        app-input-text {
          width: 568px;
          margin-right: 8px;
        }

        /* select */
        app-select {
          .ant-select {
            width: 180px;
            margin-right: 8px;
          }
        }

        /* date picker */
        nz-range-picker {
          width: 280px;
          height: 45px;
          padding: 11px 16px 10px;
          margin-right: 20px;
          border: 1px solid #d3d3e0;
          border-radius: 4px;
          .ant-picker-clear {
            right: 16px;
            top: 51%;
          }
          &:hover {
            border: 1px solid $color-primary;
          }
        }

        /* checkbox text */
        .lab_check {
          margin-right: 35px;
          line-height: 45px;
          cursor: pointer;
          /* 기본 */
          .inp_check {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            outline: 0;
            cursor: pointer;
            input[type='checkbox'] {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1;
              cursor: pointer;
              opacity: 0;
            }
            .ico_check {
              display: block;
              position: relative;
              top: 0;
              left: 0;
              width: 16px;
              height: 16px;
              background-color: #fff;
              border: 1px solid #d3d3e0;
              border-radius: 4px;
              transition: all 0.3s;
              &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 12px;
                height: 12px;
                margin: -6px 0 0 -6px;
                background-color: #1890ff;
                border-top: 0;
                border-left: 0;
                transform: scale(1);
                opacity: 0;
                transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
              }
            }

            /* 체크 했을때 */
            &.inp_checked {
              .ico_check {
                border-color: $color-primary;
                background-color: $color-primary;
                &::after {
                  opacity: 1;
                  background: url(../images/ico_check.svg) 0 0 no-repeat;
                  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
                }
              }
            }
          }

          /* 체크박스 텍스트 */
          .txt_checkbox {
            font-size: 14px;
            letter-spacing: 0.14px;
            color: $color-text3;
          }
        }

        /* 검색버튼 */
        .btn_search {
          width: 160px;
          height: 45px;
          cursor: pointer;
          @include btn-solid2;
          &:hover {
            @include btn-solid2-hover;
          }
        }
      }
    }

    /* operation 버튼 영역 */
    .wrap_table {
      data-management-operations {
        .btn_data {
          display: flex;
          justify-content: space-between;
          button {
            @include btn-static;
            width: 100px;
            height: 36px;
            font-size: 14px;
            padding: 0 10px;
            cursor: pointer;
            letter-spacing: 0.14px;
            &:hover {
              @include btn-hover;
            }
          }

          .btn_left {
            .btn_temp {
              .ico_delete {
                background: url(../images/ico_trash.svg) 0 0 no-repeat;
              }
            }
            .btn_new {
              margin-left: 8px;
              .ico_plus {
                background: url(../images/ico_plus_16.svg) 0 0 no-repeat;
              }
            }
          }

          .btn_right {
            .btn_excel {
              width: 140px;
              .ico_excel {
                width: 16px;
                height: 16px;
                background: url(../images/ico_excel.png);
                background-repeat: no-repeat;
                background-size: 16px 16px;
              }
            }
          }
        }
      }
    }

    /* 테이블 영역 */
    .grid_data_table {
      overflow-x: scroll;
      margin-top: 12px;
      border: 1px solid #dfdfe5;
      border-radius: 4px;
      data-management-table {
        .ant-table-wrapper {
          .ant-table {
            thead {
              th {
                background-color: #f3f3f8;
                &::before {
                  display: none;
                }
                &:not(:last-child):not(.ant-table-selection-column) {
                  border-right: 1px solid #dfdfe5;
                }
                /* 체크박스 */
                .ant-table-selection {
                  height: 100%;
                  .ant-checkbox-wrapper {
                    height: 100%;
                    align-items: center;
                    .ant-checkbox {
                      top: auto;
                    }
                  }
                }
              }
              /* 테이블 타이틀 */
              .ant-table-column-title {
                .ant-table-column-title {
                  span {
                    @include text-hidden-default;
                  }
                }
                span {
                  display: block;
                  font-size: 16px;
                  font-weight: $font-bold;
                  color: #000;
                  letter-spacing: 0.16px;
                  &:first-child {
                    font-size: 12px;
                    font-weight: $font-medium;
                    color: #8b8b9a;
                  }
                }
              }

              /* 정렬 아이콘 */
              .ant-table-column-sorter {
                cursor: pointer;
                .ant-table-column-sorter-inner {
                  margin-top: 5px;
                  .anticon {
                    @include btn_arrow;
                    display: block;
                    width: 6px;
                    height: 6px;
                    margin: 0 0 6px 0;
                    svg {
                      display: none;
                    }
                    &:first-child {
                      transform: rotate(-45deg);
                    }
                    &.active {
                      border-top: 1px solid #1c1c20;
                      border-right: 1px solid #1c1c20;
                    }
                  }
                }
              }

              /* filter 아이콘 */
              nz-filter-trigger {
                align-items: center;
                margin: 0 0 0 8px;
                cursor: inherit;
                .ant-table-filter-trigger {
                  width: 16px;
                  height: 16px;
                  margin: 0;
                  background: url(../images/ico_filter_line.svg) 0 0 no-repeat;
                  cursor: pointer;
                  &:hover {
                    background: url(../images/ico_filter_line_hover.svg) 0 0 no-repeat;
                  }
                  &.ant-table-filter-open {
                    background: url(../images/ico_filter_line_active.svg) 0 0 no-repeat;
                  }
                }
                /* filter 선택시 카운팅 */
                .filter_count {
                  display: none;
                  position: absolute;
                  right: -13px;
                  top: -13px;
                  width: 20px;
                  height: 20px;
                  font-size: 10px;
                  font-weight: $font-bold;
                  border-radius: 50%;
                  background-color: $color-primary;
                  color: $color-white;
                  text-align: center;
                  line-height: 20px;
                  z-index: 2;
                  cursor: default;
                  &.count_on {
                    display: block;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  border-bottom: 0 none;
                  &:not(:last-child):not(.ant-table-selection-column) {
                    border-right: 1px solid #dfdfe5;
                  }
                  .txt_column {
                    @include text-hidden-default;
                    line-height: 34px;
                  }
                  button {
                    font-size: 14px;
                    font-weight: $font-medium;
                    padding: 8px 12px;
                    border: 1px solid #d3d3e0;
                    border-radius: 4px;
                    background: $color-white;
                    line-height: 16px;
                    letter-spacing: 0.14px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* popup */
.pop_data {
  /* popup 공통 */
  .popup_data {
    width: 380px;
    height: 400px;
    background-color: $color-white;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0px 15px 30px 1px #d8d8df;
    &.pop_data_new {
      .pop_top {
        margin-bottom: 12px;
      }
    }
    .pop_top {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .tit_temp {
        font-size: 18px;
        font-weight: $font-bold;
        letter-spacing: 0.18px;
        color: #000;
        line-height: 22px;
      }
      /* 닫기 버튼 */
      .btn_comm {
        &.btn_close {
          position: relative;
          display: block;
          width: 18px;
          height: 18px;
          background: #fff;
          margin-top: 5px;
          cursor: pointer;
          &::after,
          &::before {
            @include btn-close;
            top: 0;
            height: 18px;
            margin: 0;
            background-color: #000;
          }
          &::after {
            transform: rotate(45deg);
          }
          &::before {
            transform: rotate(-45deg);
          }
        }
      }
    }

    /* 버튼 여러개 정렬 */
    .box_btn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .btn_refresh {
        display: block;
        width: 45px;
        height: 45px;
        border: 1px solid $color-primary;
        border-radius: 4px;
        background-color: $color-white;
        cursor: pointer;
        @include img-text-hidden;
        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          margin: 0 auto;
          background: url(../images/ico_reset_purple.svg) 0 0 no-repeat;
        }
      }

      .btn_apply {
        @include btn-solid2;
        display: block;
        width: 263px;
        height: 45px;
        font-size: 14px;
        font-weight: $font-medium;
        color: $color-white;
        letter-spacing: 0.14px;
        cursor: pointer;
        &:hover {
          @include btn-solid2-hover;
        }
      }
    }
  }

  /* setting pop */
  .pop_data_setting {
    padding: 32px 19px 32px 32px;
    ul {
      overflow-y: auto;
      height: 294px;
      padding-right: 15px;
      li {
        position: relative;
        display: flex;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
        .txt_item {
          display: block;
          width: 248px;
          @include text-hidden-default;
        }
        .btn_comm {
          position: absolute;
          top: 2px;
          width: 16px;
          height: 16px;
          font-weight: $font-medium;
          letter-spacing: 0.15px;
          color: $color-text2;
          cursor: pointer;
        }
        .btn_view {
          right: 32px;
          background: url(../images/ico_set_show.svg) 0 0 no-repeat;
          &.off {
            background: url(../images/ico_set_hide.svg) 0 0 no-repeat;
          }
        }
        .btn_modify {
          right: 0;
          background: url(../images/ico_edit.svg) 0 0 no-repeat;
          &:disabled {
            background: url(../images/ico_edit_off.svg) 0 0 no-repeat;
            cursor: default;
          }
        }
      }
    }
  }

  /* new popup */
  .pop_data_new {
    em {
      display: block;
      font-weight: $font-bold;
      margin-bottom: 8px;
      color: #000;
      line-height: 18px;
      letter-spacing: 0.15px;
    }

    ul {
      overflow-y: auto;
      height: 211px;
      li {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 8px 12px;
        margin-top: 8px;
        border-radius: 4px;
        background-color: $color-main;
        &:first-child {
          margin-top: 0;
        }
        .txt_item {
          display: block;
          width: 248px;
          @include text-hidden-default;
          letter-spacing: 0.14px;
        }
        .btn_create {
          @include btn-static;
          font-size: 12px;
          font-weight: $font-medium;
          padding: 0 10px;
          cursor: pointer;
          &:hover {
            @include btn-hover;
          }
        }
      }
    }
    .btn_submit {
      @include btn-solid2;
      display: block;
      width: 100%;
      height: 45px;
      font-size: 14px;
      font-weight: $font-medium;
      margin-top: 20px;
      color: $color-white;
      letter-spacing: 0.14px;
      cursor: pointer;
      &:hover {
        @include btn-solid2-hover;
      }
    }
  }

  /* filter option */
  .pop_data_filter {
    height: 480px;
    .inp_text_comm {
      width: 100%;
    }
    ul {
      overflow-y: auto;
      height: 254px;
      padding-top: 15px;
      li {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 8px 12px;
        margin-top: 8px;
        border-radius: 4px;
        &:first-child {
          margin-top: 0;
        }
        .txt_item {
          display: block;
          width: 248px;
          @include text-hidden-default;
          letter-spacing: 0.14px;
        }
        label {
          display: flex;
          cursor: pointer;
          .inp_check {
            .inp_check_comm {
              margin-right: 8px;
            }
            .ico_check {
              width: 20px;
              height: 20px;
            }
          }
          .txt_checkbox {
            color: #333337;
            letter-spacing: 0.15px;
          }
        }
      }
    }
  }

  /* filter option date */
  .pop_data_date {
    height: 208px;
    /* date picker */
    nz-range-picker {
      width: 100%;
      height: 45px;
      padding: 11px 16px 10px;
      border: 1px solid #d3d3e0;
      border-radius: 4px;
      .ant-picker-clear {
        right: 16px;
        top: 51%;
      }
      &:hover {
        border: 1px solid $color-primary;
      }
    }
  }
}
