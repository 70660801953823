@charset "utf-8";

/* tab 컴포넌트 커스텀 */
.wise-tabmenu{
    position:relative;
    /* 버튼 우측 bar */
    &:after{
        content: "";
        position:absolute;
        right:0;
        top:12px;
        width: 1px;
        height: 12px;
        background-color: $color-text;
        z-index: 2;
    }
    /* 리스트 기본 하단 보더라인 */
    &::before{
        content: "";
        position:absolute;
        right:0;
        bottom:0;
        width: 100%;
        height: 1px;
        background-color:#D3D3E0;
        z-index: 1;
    }
    .wise-tabmenu-nav-content {
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        scrollbar-width: none;
        overscroll-behavior: contain auto;
        margin-right: 72px;

        /* 탭 메뉴 */
        .wise-tabmenu-nav{
            display: flex;
            height: 36px;
            .wise-tabmenuitem{
                position: relative;
                max-width:180px;
                flex: 0 0 auto;
                /* 기본 탭 */
                .wise-menuitem-link{
                    display: block;
                    margin:0 8px;
                    border-radius: 0;
                    border:0 none;
                    background: transparent;
                    color:$color-point;
                    letter-spacing: .16px;

                    .wise-menuitem-text{
                        @include text-hidden-default;
                        font-size:16px;
                        font-weight:$font-medium;
                        line-height:36px;
                        letter-spacing: .16px;
                    }
                }

                /* hover 탭 */
                &:hover{
                    .wise-menuitem-link{
                        .wise-menuitem-text{
                            color:$color-primary;
                            font-weight:$font-bold;
                        }
                    }
                    &:after{
                        content:'';
                        position:absolute;
                        left:0;
                        bottom:-1px;
                        width:100%;
                        background: transparent;
                        color:$color-primary;
                        border-bottom:2px solid $color-primary;
                        z-index: 2;
                    }
                }

                /* active 탭 */
                &.wise-highlight{
                    .wise-menuitem-link{
                        .wise-menuitem-text{
                            color:$color-primary;
                            font-weight: $font-bold;
                        }
                    }
                    &:after{
                        content:'';
                        position:absolute;
                        left:0;
                        bottom:0;
                        width:100%;
                        background: transparent;
                        color:$color-primary;
                        border-bottom:2px solid $color-primary;
                        z-index: 2;
                    }
                }
            }
        }
    }
    /* prev,next 버튼 */
    button{
        position: absolute;
        top: 0;
        z-index: 2;
        width: 36px;
        height: 36px;
        background:$color-main;
        font-size:0;
        border-bottom: 1px solid #D3D3E0;
        cursor: pointer;
        &.wise-tabmenu-nav-prev{
            right:36px;
            &:before{
                content:'';
                @include btn_arrow;
                margin:0 0 0 6px;
                transform: rotate(225deg);
            }
        }
        &.wise-tabmenu-nav-next{
            right: 0;
            &:before{
                content:'';
                @include btn_arrow;
                margin:0;
                transform: rotate(45deg);
            }
        }
        &:hover{
            &::before, &::after{
                border-top:1px solid $color-text2;
                border-right:1px solid $color-text2;
            }
        }
        &:disabled{
            cursor: not-allowed;
            &::before{
                border-top: 1px solid #A3A3B2;
                border-right: 1px solid #A3A3B2;
            }
        }
    }
}
