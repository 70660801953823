@charset "utf-8";
.ant-drawer-body{
    padding:0;
    background: linear-gradient(180deg, #F5F9FF 0%, $color-background 100%);
    .wrap_core{
        /* corechat 상단 */
        .core_top{
            display: flex;
            justify-content: space-between;
            padding:14px 28px 9px;
            .ico_corechat{
                width:106px;
                height:36px;
                font-size:0;
                background: url(../images/ico_corechat_head.png) 0 0 no-repeat;
                background-size: 106px 36px;
            }

            .btn_close{
                display: block;
                width:18px;
                height:18px;
                font-size:0;
                margin-top: 9px;
                background: url(../images/btn_close.svg) 0 0 no-repeat;
                cursor: pointer;
            }
        }

        /* corechat 컨텐츠 인트로 영역 */
        .core_cont{
            margin:92px 0 0;
            .img_chat_logo{
                width:48px;
                height:48px;
                background: url(../images/img_chat_logo.png) 0 0 no-repeat;
                background-size: 48px 48px;
                margin:0 auto;
            }
            .txt_corechat{
                font-size: 24px;
                font-weight: 600;
                margin-top:16px;
                color:$color-text2;
                letter-spacing: -0.24px;
                text-align: center;
            }
            .list_info{
                padding: 40px 80px 0;
                li{
                    margin-top:4px;
                    &:first-child{
                        margin-top:0;
                    }
                    .link_info{
                        display: block;
                        position:relative;
                        padding:24px 44px 26px;
                        border:1px solid #DFDFE5;
                        border-radius: 4px;
                        background-color: $color-white;
                        &.link_code{
                            &::before{
                                content:'';
                                position:absolute;
                                left:24px;
                                top:29px;
                                width:14px;
                                height:14px;
                                background: url(../images/ico_core_doc.svg) 0 0 no-repeat;
                            }
                        }
                        &.link_unipass{
                            &::before{
                                content:'';
                                position:absolute;
                                left:24px;
                                top:29px;
                                width:14px;
                                height:14px;
                                background: url(../images/ico_core_find.svg) 0 0 no-repeat;
                            }
                        }
                        .tit_info{
                            color:$color-text2;
                            font-size: 15px;
                            font-weight: 600;
                            letter-spacing: .15px;
                        }
                        .txt_info{
                            font-size:14px;
                            color:#6F6F80;
                            letter-spacing: .15px;
                        }
                    }
                }
            }
        }

        /* corechat 채팅입력 영역 */
        core-chat-chat-list{
            .core_cont_chat{
                .list_chat{
                    width: 100%;
                    overflow: hidden auto;
                    height: calc(100vh - 350px);
                    .box_chat{
                        position:relative;
                        display: flex;
                        padding: 36px 40px 0;

                        /* ai일 경우 */
                        &.box_convey{
                            .img_user{
                                background: url(../images/img_chat_convey_v2.png) 0 0 no-repeat;
                                background-size: 32px 32px;
                            }
                        }
                        .img_user{
                            width:32px;
                            height:32px;
                            margin-right:14px;
                            background: url(../images/img_chat_user.png) 0 0 no-repeat;
                            background-size: 32px 32px;
                        }
                        .chat_right{
                            display: block;
                            flex-grow: 1;
                            width:270px;
                            .chat_user{
                                font-size: 15px;
                                font-weight: 600;
                                color:$color-text2;
                                @include text-hidden-default;
                                letter-spacing: -0.15px;
                            }

                            .chat_sub{
                                display: block;
                                width:474px;
                                padding-top:4px;
                                font-size: 14px;
                                font-weight: 500;
                                color:#8B8B9A;
                                line-height: 16px;
                                letter-spacing: -0.14px;
                                text-align: left;
                                word-break: break-all;
                                white-space: pre-line;
                                //@include text-hidden;
                            }
                        }
                    }
                }

            }
        }

        /* corechat 하단 */
        .core_bottom{
            position: absolute;
            bottom: 0;
            width:100%;
            .bg_chat{
                position:relative;
                width:600px;
                height:120px;
                background-size: 600px 120px;
                background-repeat: no-repeat;
                background-image: url(../images/bg_chat.png);
            }
            .list_shortcut{
                display: flex;
                margin:0 40px;
                li{
                    .link_shortcut{
                        display: block;
                        font-weight: 500;
                        margin-right:8px;
                        padding:10px;
                        border:1px solid #D3D3E0;
                        border-radius: 4px;
                        background-color: $color-white;
                        color:$color-text2;
                        letter-spacing: -0.14px;
                    }
                }
            }
            /* 채팅 박스 영역 */
            .box_chat{
                margin:8px 40px 40px;
                .inp_chat{
                    position:relative;
                    display: block;
                    textarea{
                        display:block;
                        width:100%;
                        height:80px !important;
                        border:1px solid #D3D3E0;
                        border-radius: 4px;
                        padding: 21px 45px 0 16px;
                        resize: none;
                    }
                    .btn_comm{
                        position:absolute;
                        right:12px;
                        bottom:12px;
                        width:28px;
                        height:28px;
                        background-color: transparent;
                        &.btn_enter{
                            background: url(../images/ico_chat_enter.svg) 0 0 no-repeat;
                        }
                        &.on_enter{
                            background: url(../images/ico_chat_enter_on.svg) 0 0 no-repeat;
                            cursor: pointer;
                        }
                        &.btn_pause{
                            background-image: url(../images/ico_pause.svg);
                            background-size: 28px 28px;
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

}

