@charset "utf-8";

.convey_head {
  position: relative;
  height: 56px;
  background: #fff;
  box-shadow: 0px 1px 8px 0px #dfdfea;
}

.convey_cont {
  /* width:calc(1920px - 80px);
    height: calc(100% - 56px);*/
  max-width: 1920px;
  min-height: calc(100vh - 56px);
  padding-left: 80px;
}

.ico_comm,
.img_comm,
.link_comm,
.btn_comm {
  display: block;
  overflow: hidden;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
  color: transparent;
}

/* 버튼 공통 */
.btn_shared {
  display: block;
  background-color: $color-primary;
  border-radius: 4px;
  color: $color-white;
  cursor: pointer;
  &:hover {
    @include btn-solid;
  }
}

.btn_shared_gray {
  display: block;
  background-color: $color-white;
  border: 1px solid $color-text;
  border-radius: 4px;
  color: $color-point;
  cursor: pointer;
  &:hover {
    @include btn-gray-line;
  }
}

/* 우측 정렬 2개 버튼 */
.box_btn {
  button {
    width: 120px;
    height: 45px;
    @include btn-purple-line;
    margin-top: 30px;
    cursor: pointer;
    &:first-child {
      margin-right: 8px;
      &:hover {
        @include btn-purple-line-hover;
      }
    }
    &:last-child {
      @include btn-solid2;
      &:hover {
        @include btn-solid2-hover;
      }
    }
  }
}

/* loading */
.loading {
  padding: 0 0 0 22px;
  color: #1c1c20;
  font-size: 0;
  text-indent: -9999px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 18px;
    height: 18px;
    -webkit-animation: spin 0.8s infinite linear;
    animation: spin 0.8s infinite linear;
    border: 3px #dfdfe5 solid;
    border-left-color: $color-primary;
    border-radius: 100%;
  }
}

/* input 닫기 버튼 */
.input_close {
  display: none;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  background: url(../images/ico_close.svg) 0 0 no-repeat;
  cursor: pointer;
}

/* datepicker 기본 placeholder text color */
.ant-picker-input {
  input::placeholder {
    color: #8b8b9a;
    font-size: 15px;
    letter-spacing: -0.15px;
  }
}
/* datepicker icon */
.ant-picker-suffix {
  display: block;
  width: 16px;
  height: 16px;
  background: url(../images/ico_calendar.svg) 0 0 no-repeat;
  svg {
    display: none;
  }
}
/* datepicker 삭제 아이콘 */
.ant-picker-clear {
  width: 16px;
  height: 16px;
  background: url(../images/ico_close.svg) 0 0 no-repeat;
  cursor: pointer;
  svg {
    display: none;
  }
}

/* 상단 h2 타이틀 공통 */
.tit_convey_text {
  @include title-text;
}

/* font 20px 서브 타이틀 */
.tit_sub_text {
  display: inline-block;
  font-size: 20px;
  font-weight: $font-bold;
  vertical-align: top;
  letter-spacing: 0.2px;
}

/* 테이블 공통 */
.wrap_table {
  padding: 40px;
  margin-top: 16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
}

/* popup common*/
.pop_wrap_comm {
  position: absolute;
}
.ant-modal-close {
  top: 12px;
  right: 12px;
}
.pop_wrap {
  width: 450px;
}

/* popup custom */
.cdk-global-scrollblock {
  overflow: hidden;
}
.ant-modal-mask.cdk-overlay-backdrop-showing {
  background-color: rgba(0, 0, 0, 0.3);
}
.ant-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* width: 100% !important;
    height: 100%;*/
  top: auto;
  justify-content: center;
  padding: 0;
  .ant-modal-close-x {
    svg {
      display: none;
    }
    .anticon {
      width: 16px;
      height: 16px;
      background-image: url(../images/ico_close_default.svg);
      background-repeat: no-repeat;
      background-size: 16px 16px;
    }
  }
  .ant-modal-body {
    padding: 0;
    .pop_wrap {
      .pop_inner {
        padding: 32px;
        border-radius: 4px;
        background: $color-white;
        z-index: 4;
        .pop_top {
          display: flex;
          .ico_pop {
            display: block;
            width: 48px;
            height: 48px;
            background-image: url(../images/ico_notice.png);
            background-repeat: no-repeat;
            background-size: 48px 48px;
          }
          .tit_popup {
            font-size: 22px;
            font-weight: 600;
            padding-left: 12px;
            letter-spacing: -0.22px;
            line-height: 48px;
            color: #000;
          }
        }

        .pop_content {
          padding: 0 0 0 61px;
          .txt_pop {
            line-height: 25px;
            letter-spacing: 0.16px;
          }
        }

        .pop_bottom {
          overflow: hidden;
          display: flex;
          justify-content: right;
          padding-top: 42px;
          .btn_close {
            width: 100px;
            height: 36px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 4px;
            border: 1px solid #d3d3e0;
            background: $color-white;
            letter-spacing: -0.14px;
            & + .btn_close {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1919px), (max-height: 1079px) {
  body {
    overflow-x: auto;
    min-width: 1900px;
    &.wise_login {
      min-width: 100%;
    }
    .convey_cont {
      width: 100%;
      .container {
        .right {
          margin: 25px;
        }
      }
      /* scanning intro */
      .intro_view {
        padding: 70px;
        .tit_scan {
          font-size: 35px;
          padding-top: 20px;
        }
        .txt_scan_info {
          padding-top: 20px;
        }
        .img_scan_info {
          padding-top: 40px;
        }
      }
    }
  }
}

/* e2e - corechat */
.btn_corechat {
  width: 106px;
  height: 36px;
  font-size: 0;
  background-image: url(../images/ico_corechat_head.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 106px 36px;
  background-color: transparent;
}
