@charset "utf-8";

.detail_page {
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  background-color: $color-white;
  border-left: 1px solid #dfdfe5;
  box-shadow: 0px 15px 30px 1px #d8d8df;
  display: block;
  /* 버튼 클릭시 상세 슬라이드 오픈 */
  &.open {
    z-index: 99;
    .inner_detail_connect {
      opacity: 1;
      width: 920px;
      /* 내용이 바로 보이지 않도록 딜레이 */
      transition-delay: 0.1s;
    }
  }
  .inner_detail_connect {
    /* new 버튼 클릭 전 숨기기 */
    opacity: 0;
    width: 0;
    height: 100%;
    padding: 48px 0;
    transition:
      opacity,
      width 0.3s ease-in-out;
    /* 타이틀 공통 */
    .tit_data {
      @include title-text;
      font-size: 24px;
      padding: 0;
      color: #000;
      line-height: 25px;
      letter-spacing: 0.24px;
    }
    .tit_data_detail {
      @include title-text;
      font-size: 20px;
      padding: 20px 0 12px 0;
      line-height: 24px;
    }
    /* 닫기 버튼 */
    .btn_comm {
      &.btn_close {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        margin-top: 5px;
        background: transparent;
        cursor: pointer;
        &::after,
        &::before {
          @include btn-close;
          top: 0;
          height: 20px;
          margin: 0;
          background-color: #000;
        }
        &::after {
          transform: rotate(45deg);
        }
        &::before {
          transform: rotate(-45deg);
        }
      }
    }

    /* top 영역 */
    .detail_connect_top {
      display: flex;
      justify-content: space-between;
      padding: 0 48px;
    }
    &:has(.detail_connect) {
      .detail_connect {
        .tit_data_detail {
          padding: 30px 0 12px 0;
        }
      }
      .detail_document {
        border-top: 1px solid #dfdfe5;
      }
    }

    /* connection 영역 */
    .detail_connect {
      padding: 0 48px 60px;
      .box_connect {
        table {
          border-radius: $b-radius;
          border-style: hidden;
          box-shadow: 0 0 0 1px $b-line-gray;
          table-layout: fixed;
          thead {
            th {
              border-right: 1px solid $b-line-gray;
              .lab_temp {
                display: block;
                font-weight: $font-bold;
                font-size: 14px;
                padding: 10px 0;
                background: $color-main;
                color: $color-text2;
                text-align: center;
                letter-spacing: 0.16px;
                &.connect_on {
                  color: $color-primary;
                }
              }
              &:first-child {
                color: #1c1c20;
              }
              &:last-child {
                border-right: 0 none;
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 10px 0;
                border-right: 1px solid $b-line-gray;
                border-bottom: 1px solid $b-line-gray;
                .txt_connect {
                  display: block;
                  font-size: 14px;
                  font-weight: $font-medium;
                  text-align: center;
                  color: #6f6f80;
                  letter-spacing: 0.15px;
                  @include text-hidden-default;
                  &.connect_on {
                    color: $color-primary;
                  }
                }
                &:first-child {
                  .txt_connect {
                    color: #1c1c20;
                  }
                }
                &:last-child {
                  border-right: 0 none;
                }
              }
            }
          }
        }
      }
    }

    /* Document Details 영역 */
    .detail_document {
      padding: 32px 48px 60px;
      textarea {
        width: 100%;
        min-height: 100px;
        font-size: 15px;
        padding: 5px 16px 0 16px;
        border: 1px solid #d3d3e0;
        border-radius: 4px;
        color: #1c1c20;
        line-height: 24px;
        letter-spacing: -0.15px;
        &:placeholder-shown {
          font-variant: inherit;
          font-size: 15px;
          color: #8b8b9a;
          padding-top: 10px;
        }
        &.ant-input-focused,
        &:focus {
          border-color: $color-primary;
          box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
        }
        &:disabled {
          background-color: $color-main;
          &:hover {
            cursor: not-allowed;
          }
        }
        &:hover:not(:disabled) {
          border-color: $color-primary;
        }
        &::placeholder {
          color: #8b8b9a;
        }
      }

      .detail_doc_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4.tit_data_detail {
          font-size: 24px;
        }
        .box_btn {
          button {
            width: 120px;
            height: 45px;
            @include btn-purple-line;
            cursor: pointer;
            &.btn_tracking {
              margin-right: 8px;
              &:hover {
                @include btn-purple-line-hover;
              }
            }
            &.btn_save {
              @include btn-solid2;
              &:hover {
                @include btn-solid2-hover;
              }
            }
          }
        }
      }

      /* Document Detail 공통 입력태그들 */
      .wrap_temp {
        .temp_row {
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
          .lab_temp {
            display: block;
            font-size: 16px;
            font-weight: $font-medium;
            margin-top: 16px;
            color: #4d4d51;
            line-height: 20px;
            letter-spacing: 0.16px;
            /* 필수값일 경우 */
            .temp_essential {
              color: $color-primary;
              margin-left: -13px;
            }
          }

          .inp_temp {
            overflow: hidden;
            display: flex;
            position: relative;
            margin-top: 8px;
            /* 필수값 문구 */
            .txt_essential {
              display: none;
            }
            /* 필수값 에러 */
            &.inp_essential {
              flex-direction: column;
              app-input-text {
                .ant-input-affix-wrapper {
                  border: 1px solid #f73d3f;
                }
                .ant-input-affix-wrapper {
                  &.ant-input-affix-wrapper-focused,
                  .ant-input-affix-wrapper:focus {
                    box-shadow: 0px 2px 6px 0px rgba(247, 61, 63, 0.35);
                  }
                }
              }
              .txt_essential {
                display: block;
                font-size: 12px;
                font-weight: 500;
                padding-top: 4px;
                color: #f73d3f;
                line-height: 22px;
                letter-spacing: -0.14px;
                &::before {
                  content: '';
                  display: inline-block;
                  width: 12px;
                  height: 12px;
                  margin: 5px 4px 0 0;
                  background-image: url(../images/ico_essential.png);
                  background-repeat: no-repeat;
                  background-size: 12px 12px;
                  vertical-align: top;
                }
              }
            }
            /* input 1개 */
            app-input-text {
              width: 100%;
              .ant-input-affix-wrapper-disabled {
                background-color: $color-main;
                .ant-input {
                  background-color: $color-main;
                  color: #1c1c20;
                }
              }
            }
            /* textarea 1개 */
            app-textarea {
              width: 100%;
            }
            /* select 1개 */
            app-select {
              width: 100%;
              .ant-select {
                width: 100%;
                &.ant-select-single:not(.ant-select-customize-input, .ant-select-disabled) {
                  &:hover {
                    .ant-select-selector {
                      border-color: $color-primary;
                    }
                  }
                }
                &.ant-select-disabled {
                  .ant-select-selector {
                    background-color: $color-main;
                  }
                }
              }
            }
            &.inp_error {
              .ant-select {
                &.ant-select-single:not(.ant-select-customize-input, .ant-select-disabled) {
                  &:hover {
                    .ant-select-selector {
                      border-color: red;
                    }
                  }
                }
              }
            }
            /* date picker */
            app-date-picker {
              display: block;
              width: 100%;
              .ant-picker {
                display: block;
                width: 100%;
                height: 45px;
                padding: 0 16px;
                border-radius: 4px;
                line-height: 42px;
              }
            }

            /* 입력태그 2개이상 */
            &.wrap_inp_temp {
              /* 공통 */
              .inp_temp_text {
                flex-grow: 1;
                margin-right: 8px;
                /* 자동완성 select */
                &.inp_temp_select {
                  width: 90px;
                }
                &:last-child {
                  width: 294px;
                  margin-right: 0;
                }
              }
              /* 타이틀이 package일 경우 width */
              &.inp_package_text {
                .inp_temp_text {
                  width: 192px;
                }
              }
            }
            /* select 공통 */
            app-autocomplete {
              width: 100%;
              .ant-select:not(.ant-select-customize-input) {
                width: 100%;
              }
              .ant-select:not(.ant-select-disabled):hover {
                .ant-select-selector {
                  border-color: $color-primary;
                }
              }
            }
            /* 인풋 text 삭제 아이콘 */
            .ant-input-suffix,
            .ant-select-clear {
              .anticon-close-circle {
                width: 16px;
                height: 16px;
                background: url(../images/ico_close.svg) 0 0 no-repeat;
                cursor: pointer;
              }
              svg {
                display: none;
              }
            }
            .ant-select-clear {
              .anticon-close-circle {
                margin-top: -2px;
              }
            }
          }
        }
      }

      bl-document-detail-form {
        .tit_data_detail {
          padding: 32px 0 0 0;
          line-height: 24px;
        }

        /* Document Detail - basic info */
        .detail_doc_basic {
          .wrap_temp {
            .temp_row {
              .temp_essential {
                margin-left: -5px;
              }
            }
          }
          /* input 2개 */
          .wrap_inp_temp {
            display: flex;
            .temp_row {
              width: 406px;
              margin-top: 0;
              &:first-child {
                margin-right: 12px;
              }
            }
          }
        }
        /* Document Detail - shipping paties */
        .detail_doc_shipping {
          .data_sort {
            position: relative;
            .inp_check {
              position: absolute;
              right: 0;
              top: 0;
              label {
                display: flex;
                font-size: 14px;
                letter-spacing: 0.14px;
                .ico_check {
                  margin-right: 8px;
                }
              }
            }
          }
        }

        /* Document Detail - cargo info */
        .detail_doc_cargo {
          .wrap_inp_temp {
            display: flex;
            .temp_row {
              width: 406px;
              margin-top: 0;
              &:first-child {
                margin-right: 12px;
              }
            }
          }
          .wrap_inp_temp_sort {
            display: flex;
            flex-wrap: wrap;
            .temp_row {
              width: 197px;
              margin-top: 0;
              margin-right: 12px;
              &:nth-child(4) {
                margin-right: 0;
              }
            }
          }
        }

        /* Document Detail - Schedule info */
        .detail_doc_schedule {
          .wrap_temp {
            .temp_row {
              .inp_temp.wrap_inp_temp {
                .inp_temp_select {
                  flex-grow: 0;
                  width: 197px;
                }
              }
            }
          }

          .wrap_inp_temp {
            display: flex;
            flex-wrap: wrap;
            &:has(.wrap_inp_bundle) {
              flex-wrap: nowrap;
            }
            .temp_row {
              width: 406px;
              margin-top: 0;
              margin-right: 12px;
              &:nth-child(even) {
                margin-right: 0;
              }
            }
            .wrap_inp_bundle {
              display: flex;
              .temp_row {
                width: 197px;
              }
            }
          }
        }
      }

      /* Document Detail - container */
      bl-document-detail-container-form,
      booking-document-detail-container-form {
        .inner_container {
          margin: 0;
          .container_top {
            padding: 40px 0 16px;
            .btn_add {
              margin-top: 16px;
            }
          }
        }
      }
    }

    /* Creation Info 영역 */
    .detail_creation {
      border-top: 1px solid#DFDFE5;
      padding: 60px 48px;
      h4.tit_data_detail {
        font-size: 24px;
      }
      dl {
        display: flex;
        padding-top: 20px;
        dt {
          display: flex;
          width: 20%;
          font-size: 16px;
          font-weight: $font-medium;
          margin-right: 90px;
          color: #4d4d51;
          letter-spacing: 0.16px;
          &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
        &:nth-of-type(1) {
          dt {
            &::before {
              background: url(../images/ico_clock.svg) 0 0 no-repeat;
            }
          }
        }
        &:nth-of-type(2) {
          dt {
            &::before {
              background: url(../images/ico_update.svg) 0 0 no-repeat;
            }
          }
        }
        &:nth-of-type(3) {
          dt {
            &::before {
              background: url(../images/ico_calendar.svg) 0 0 no-repeat;
            }
          }
        }
        &:nth-of-type(4) {
          dt {
            &::before {
              background: url(../images/ico_user_second.svg) 0 0 no-repeat;
            }
          }
        }
        dd {
          color: #8b8b9a;
          letter-spacing: 0.16px;
        }
      }

      .file_download {
        display: flex;
        justify-content: space-between;
        margin-top: 28px;
        padding: 20px 28px;
        border: 1px solid #dfdfe5;
        border-radius: 4px;
        &:hover {
          box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
        }
        .file_info {
          display: flex;
          flex: 1;
          font-weight: $font-medium;
          line-height: 24px;
          color: #333337;
          letter-spacing: 0.15px;
          .img_file {
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            @include img-text-hidden;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            background-position: 0 0;
            &.img_type_pdf {
              background-image: url(../images/img_file.png);
            }
            &.img_type_image {
              background-image: url(../images/img_images.png);
            }
          }
        }
        .btn_download {
          display: block;
          width: 16px;
          height: 16px;
          margin-top: 4px;
          background: url(../images/ico_download.svg) 0 0 no-repeat;
          cursor: pointer;
        }
      }
    }

    /* Comment 영역 */
    .detail_comment {
      border-top: 1px solid #dfdfe5;
      padding: 40px 48px 60px;
      h4.tit_data_detail {
        font-size: 24px;
        padding-bottom: 0;
      }

      /* 댓글 뷰영역 */
      document-comment-item,
      wms-comment-item {
        display: block;
        margin-top: 28px;
      }
      .box_comment {
        display: flex;
        //margin:14px 0;
        .img_user {
          flex: none;
          display: block;
          width: 32px;
          height: 32px;
          background: url(../images/img_profile.png) 0 0 no-repeat;
          background-size: 32px 32px;
        }
        span {
          display: block;
        }
        .chat_right {
          width: 100%;
          margin-left: 12px;
          .chat_user_name {
            font-size: 15px;
            font-weight: $font-bold;
            color: #000;
            line-height: 18px;
            letter-spacing: 0.15px;
          }
          /* 입력 내용 */
          .chat_sub {
            display: block;
            width: 780px;
            margin-top: 4px;
            color: #4d4d51;
            line-height: 22px;
            letter-spacing: 0.15px;
            word-break: break-all;
            white-space: pre-line;
          }
          .sort_content {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            /* 입력 후 날짜,시간 */
            .chat_date {
              font-size: 14px;
              margin-top: 3px;
              color: #a3a3b2;
              letter-spacing: 0.14px;
            }
            /* 입력 후 수정 삭제 버튼 */
            .box_btn_edit {
              button {
                padding-left: 18px;
                cursor: pointer;
                color: #8b8b9a;
              }

              .btn_edit {
                position: relative;
                background: url(../images/ico_edit_blur.svg) 0 0 no-repeat;
                padding-right: 12px;
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 1px;
                  height: 100%;
                  background-color: #d3d3e0;
                }
              }
              .btn_del {
                background: url(../images/ico_trash_second.svg) 0 0 no-repeat;
                margin-left: 8px;
              }
            }
          }
        }
      }

      /* 댓글 입력 영역 */
      .box_chat {
        margin-top: 40px;
        .inp_chat {
          position: relative;
          display: block;
          textarea {
            display: block;
            width: 100%;
            height: 80px !important;
            border: 1px solid #d3d3e0;
            border-radius: 4px;
            padding: 21px 45px 0 16px;
            resize: none;
          }
          .btn_comm {
            position: absolute;
            right: 12px;
            bottom: 12px;
            width: 28px;
            height: 28px;
            background-color: transparent;
            &.btn_enter {
              background: url(../images/ico_chat_enter.svg) 0 0 no-repeat;
            }
            &.on_enter {
              background: url(../images/ico_chat_enter_on.svg) 0 0 no-repeat;
              cursor: pointer;
            }
            &.btn_pause {
              background-image: url(../images/ico_pause.svg);
              background-size: 28px 28px;
              background-repeat: no-repeat;
              background-position: 0 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
