@charset "utf-8";

/* data-management template setting 페이지 */
app-template-setting-page{
    display: block;
    width: 1720px;
    margin: 0 60px 0;
    padding-bottom: 60px;
    .temp_setting{
        .tit_header{
            font-size:30px;
            font-weight:$font-bold;
            padding: 60px 0 20px;
            letter-spacing: .3px;
        }

        .temp_wrap{
            background-color: $color-white;
            padding:48px 40px 40px;
            h3{
                font-size:24px;
                font-weight:$font-bold;
                padding-bottom:15px;
                letter-spacing: -.24px;
            }

            /* temp 상단 제목 영역*/
            .temp_top{
                padding-bottom:20px;
                border-bottom:1px solid $b-line-gray;
                .temp_top_container{
                    display: flex;
                    .btn_prev{
                        overflow: visible;
                        position:relative;
                        display: block;
                        width:40px;
                        height:40px;
                        margin-top:4px;
                        border:1px solid $color-text;
                        border-radius: 50%;
                        background-color: $color-white;
                        cursor: pointer;
                        z-index: 1;
                        .ico_comm{
                            overflow: visible;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 8px;
                            height: 8px;
                            border-top: 2px solid #8B8B9A;
                            border-right: 2px solid #8B8B9A;
                            margin: -4px 0 0 -2px;
                            transform: rotate(225deg);
                        }
                        &::after{
                            content:'';
                            position:absolute;
                            right:-12px;
                            top:0;
                            width:1px;
                            height:40px;
                            background-color: #000;
                        }
                    }
                    .inp_text{
                        width:100%;
                        margin-left:20px;
                        font-size:40px;
                        font-weight:$font-bold;
                        letter-spacing: .4px;
                        outline: none;
                        &::placeholder{
                            color:$color-text;
                        }
                    }
                }
            }

            /* Data by row(unit-code,filter) 영역*/
            .temp_row{
                padding-top:40px;

                /* unit-code 영역*/
                .check_temp{
                    label{
                        margin:0 12px 0 0;
                        /* 기본 */
                        .ant-radio{
                            top: .3em;
                            .ant-radio-inner{
                                width:20px;
                                height:20px;
                                border-radius: 4px;
                                border-color:$color-text;
                                &::after{
                                    width:12px;
                                    height:12px;
                                    margin:-6px 0 0 -6px;
                                    transform: scale(1);
                                }
                            }
                        }
                        /* 선택 했을때 */
                        .ant-radio{
                            &.ant-radio-checked{
                                .ant-radio-inner{
                                    border-color: $color-primary;
                                    background-color: $color-primary;
                                    &::after{
                                        background: url(../images/ico_check.svg) 0 0 no-repeat;
                                    }
                                }
                            }
                        }
                    }
                }

                /* filter 영역*/
                .box_filter{
                    @include border-line;
                    padding:30px;
                    margin:20px 0 0;
                    box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.50);
                    .tit_filter{
                        display: block;
                        font-size:20px;
                        font-weight:$font-bold;
                        padding-bottom:12px;
                        color:$color-text2;
                        letter-spacing: .2px;
                    }
                    .form_filter{
                        display: flex;
                        .btn_done{
                            width:160px;
                            height:45px;
                            margin-left:8px;
                            @include btn-solid2;
                            letter-spacing: .15px;
                            cursor: pointer;
                            &:hover{
                                @include btn-solid2-hover;
                            }
                        }
                        .ant-select{
                            width:400px;
                        }
                        .txt_form{
                            display: block;
                            padding:0 14px;
                            line-height:45px;
                            color:$color-text4;
                            letter-spacing: .15px;
                        }
                        app-autocomplete{
                            width: 912px;
                            .ant-select{
                                width: 100%;
                            }
                        }
                        app-input-text{
                            width:912px;
                        }
                    }

                    /* flag */
                    .list_filter{
                        display: flex;
                        flex-wrap:wrap;
                        li{
                            font-size:12px;
                            margin:12px 8px 0 0;
                            padding:10px 12px 10px 12px;
                            background-color: $color-background;
                            border-radius: 4px;
                            color:#322F70;
                            &:hover{
                                background-color: #B7B5F1;
                            }
                            .btn_delete{
                                position: relative;
                                display: inline-block;
                                width:12px;
                                height:12px;
                                background-color: transparent;
                                cursor: pointer;
                                &::before, &::after{
                                    @include btn-close;
                                    left:0;
                                }
                                &::before{
                                    transform:rotate(45deg);
                                }
                                &::after {
                                    transform:rotate(-45deg);
                                }
                            }
                        }
                    }
                }
            }

            /* select columns grid 영역*/
            .temp_row.temp_grid{
                .temp_column{

                    /* select 영역 */
                    template-setting-grid-category{
                        app-select{

                            /* select 마우스 호버시 */
                            &:hover{
                                .ant-select{
                                    .ant-select-arrow{
                                        //border:0 none;
                                    }
                                    .ant-select-clear{
                                        opacity: 1;
                                        transform: none;
                                        border: 0 none;
                                        span{
                                            display: block;
                                        }
                                    }
                                }
                            }

                            /* select 기본 */
                            .ant-select{
                                width:786px;
                                margin-bottom:12px;
                            }
                        }
                    }

                    /* grid transfer 영역 */
                    .column_transfer{
                        display: flex;
                        .ag-checkbox-input-wrapper input, .ag-checkbox-input-wrapper input{
                            vertical-align: top;
                        }
                        /* 좌측 grid */
                        template-setting-grid-source-grid{
                            width:786px;
                            //margin-right:68px;
                        }
                        .btn_grid{
                            @include flex-column-all-center;
                            margin:0 16px;
                            .btn_comm{
                                width:36px;
                                height:36px;
                                @include btn-static;
                                cursor: pointer;
                                &::before{
                                    content: "";
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    @include btn_arrow;
                                    margin: -4px 0 0 -5px;
                                    transform: rotate(45deg);
                                }

                                &:nth-child(2){
                                    margin-top:14px;
                                    &::before{
                                        margin: -4px 0 0 -2px;
                                        transform: rotate(-135deg);
                                    }
                                }

                                /* 버튼 disabled */
                                &:disabled{
                                    border:1px solid #DFDFE5;
                                    background-color: #F3F3F8;
                                    cursor: default;
                                    &::before{
                                        border-top: 1px solid #A3A3B2;
                                        border-right: 1px solid #A3A3B2;
                                    }
                                }

                            }
                        }
                        /* 우측 grid */
                        template-setting-grid-target-grid{
                            width:786px;
                        }
                    }
                }
            }

            /* 하단 버튼 */
            template-setting-control{
                display: flex;
                justify-content: space-between;
                button{
                    width:120px;
                    height:45px;
                    letter-spacing: .15px;
                    cursor: pointer;
                }
                .btn_del{
                    @include btn-gray-line;
                    &:hover{
                        @include btn-gray-line-hover;
                    }
                }
                .btn_control{
                    .btn_copy{
                        @include btn-purple-line;
                        &:hover{
                            @include btn-purple-line-hover;
                        }
                    }
                    .btn_save{
                        margin-left:8px;
                        @include btn-solid2;
                        &:hover{
                            @include btn-solid2-hover;
                        }
                        &:disabled{
                            @include btn-disabled-border-none;
                        }
                    }
                }
            }
        }

    }
}